<template>
	<div class="cart">
		<apexchart
			class="apexchart"
			type="rangeBar"
			height="700"
			:options="options"
			:series="seriress"></apexchart>
		<v-dialog
			v-model="showDialog"
			width="300">
			<v-card class="pa-4">
				<div v-html="dialogText"></div>
			</v-card>
		</v-dialog>
	</div>
</template>

<script>
export default {
	name: 'WorkingHours',
	props: ['date', 'weekNames'],
	data() {
		return {
			showDialog: false,
			dialogText: null,
		};
	},
	computed: {
		seriress() {
			return [
				{
					data: this.date,
				},
			];
		},
		options() {
			return {
				colors: ['#fb5d33'],
				tooltip: {
					enabled: !this.$vuetify.breakpoint.mobile,
					custom: function ({
						series,
						seriesIndex,
						dataPointIndex,
						w,
					}) {
						const data =
							w.globals.initialSeries[seriesIndex].data[
								dataPointIndex
							];

						return (
							"<ul class='pa-4'>" +
							'<li><b>المادة:</b>: ' +
							data.name +
							'</li>' +
							'<li><b>القاعة:</b>: ' +
							data.class +
							'</ul>'
						);
					},
				},
				grid: {
					xaxis: {
						lines: {
							show: true,
						},
					},
					row: {
						colors: ['#f3f4f5', '#fff'],
					},
				},
				chart: {
					events: {
						dataPointSelection: (event, obj2, obj) => {
							const data =
								obj.w.config.series[obj.seriesIndex].data[
									obj.dataPointIndex
								];
							this.dialogText = `<p>المادة: ${data.name}</p><p>المدرس: ${data.teacher}</p><p>القاعة: ${data.class}</p>`;
							this.showDialog = true;
						},
					},
					toolbar: {
						show: false,
					},
				},
				dataLabels: {
					enabled: true,
					textAnchor: 'middle',
					formatter: function (x, obj, y) {
						return obj.w.config.series[obj.seriesIndex].data[
							obj.dataPointIndex
						].name;
					},
					style: {
						fontSize: '13px',
						fontWeight: '400',
						fontFamily: 'Fairuz, sans-serif',
					},
				},
				plotOptions: {
					bar: {
						distributed: true,
						horizontal: true,
						barHeight: '90%',
						borderRadius: 3,
						dataLabels: {
							position: 'center',
							orientation: 'horizontal',
						},
					},
				},
				yaxis: {
					labels: {
						align: 'center',
						style: {
							fontFamily: 'Fairuz, sans-serif',
							fontSize: '16px',
						},
					},
					// tickAmount: 7,
				},
				xaxis: {
					categories: this.weekNames,
					type: 'datetime',
					position: 'top',
					labels: {
						format: 'HH:mm',
						style: {
							fontFamily: 'Fairuz, sans-serif',
							fontSize: '14px',
						},
					},
				},
			};
		},
	},
};
</script>
<style></style>
