<template>
	<div
		class="page"
		v-if="marks">
		<v-row>
			<!-- cards -->

			<v-col
				cols="12"
				md="6">
				<!-- chart -->
				<v-card
					rounded="lg"
					elevation="3"
					class="mb-2 pt-2 pr-2 text-center">
					تطور معدل العلامات خلال الفترة السابقة
					<average-chart
						:offline="$isOffline.value"
						:data="
							subjectAverage?.MarksChartResults
						"></average-chart>
				</v-card>

				<!-- average -->
				<v-card
					v-if="!$isOffline.value && subjectAverage"
					rounded="lg"
					elevation="3"
					class="px-5 py-2 mb-2">
					<v-row
						class="d-flex"
						no-gutters>
						<!-- get better -->
						<v-col
							cols="7"
							class="text-center">
							<div>
								<span
									v-if="subjectAverage.MonthlyImprove === '-'"
									class="text-h4 font-weight-bold grey--text">
									-
								</span>
								<span
									v-else
									class="text-h4 font-weight-bold"
									:class="
										subjectAverage.MonthlyImprove[0] !== '-'
											? 'myGreen--text'
											: 'error--text'
									"
									dir="ltr">
									{{ subjectAverage.MonthlyImprove }}
									<v-icon
										v-if="
											subjectAverage.MonthlyImprove[0] !==
											'-'
										"
										color="myGreen"
										class="mx-n4 text-h4">
										mdi-chevron-double-up
									</v-icon>
									<v-icon
										v-else
										color="error"
										class="mx-n4 text-h4">
										mdi-chevron-double-down
									</v-icon>
								</span>
							</div>
							<div>تغير عن الشهر الماضي</div>
						</v-col>

						<v-divider vertical></v-divider>

						<!-- avreage -->
						<v-col
							cols="5"
							class="text-center">
							<div>
								<span
									class="text-h4 font-weight-bold myOrange--text">
									{{
										subjectAverage.MarksAverage?.split(
											'/',
										)[0]
									}}
								</span>
								\
								{{ subjectAverage.MarksAverage?.split('/')[1] }}
							</div>
							<div>معدل تراكمي</div>
						</v-col>
					</v-row>
				</v-card>
			</v-col>

			<!-- grades table -->
			<v-col
				cols="12"
				md="6">
				<!-- grades -->
				<div class="text-h5 mb-2">جدول العلامات السابقة</div>
				<v-card
					rounded="lg"
					elevation="3"
					class="px-1 py-3">
					<v-data-table
						:headers="headers"
						:items="
							marks
								.map((e) => e)
								.sort((a, b) => b.Test.Date - a.Test.Date)
						"
						mobile-breakpoint="0"
						hide-default-footer
						:items-per-page="-1"
						dense>
						<template v-slot:item.date="{ item }">
							{{ dateFormater(item.Test.Date) }}
						</template>
						<template v-slot:item.subject="{ item }">
							{{ item.Test.Details }}
						</template>
						<template v-slot:item.type="{ item }">
							{{ item.Test.TestType }}
						</template>
						<template v-slot:item.grade="{ item }">
							{{ item.Mark }}\{{ item.TestMaxMark }}
						</template>
					</v-data-table>
				</v-card>
			</v-col>
		</v-row>
	</div>
</template>

<script>
import { dateFormater } from '@/helpers/helpers';
import AverageChart from '@/components/AverageChart.vue';
export default {
	name: 'GradeView',

	components: { AverageChart },

	data() {
		return {
			headers: [
				{ text: 'التاريخ', value: 'date', class: 'primary--text' },
				{
					text: 'المحتوى',
					value: 'subject',
					sortable: false,
					class: 'primary--text',
				},
				{
					text: 'النوع',
					value: 'type',
					sortable: false,
					class: 'primary--text',
				},
				{
					text: 'العلامة',
					value: 'grade',
					sortable: false,
					class: 'primary--text',
				},
			],

			marks: [],
			subjectAverage: null,
			getBetter: 0,
		};
	},

	methods: {
		dateFormater,
		getGetBetter(val) {
			this.getBetter = val;
		},
		fetch() {
			this.$eventBus.$emit('page-loader', true);
			Promise.all([
				this.$store
					.dispatch('marks/fetchMarkBySubject', {
						subjectId: this.$route.params.id,
					})
					.then((res) => {
						this.marks = res;
					}),
				this.$store
					.dispatch('marks/fetchSubjectAverage', {
						subjectId: this.$route.params.id,
					})
					.then((res) => {
						this.subjectAverage = res;
					}),
			]).finally(() => {
				this.$eventBus.$emit('page-loader', false);
			});
		},
	},

	mounted() {
		this.fetch();
	},
};
</script>
