import * as types from '../mutations';

export default {
	namespaced: true,

	state: {
		statistics: [],
	},

	actions: {
		fetchStatisticsByMonth({ rootState }, { month }) {
			return window.axios
				.get(
					`center/${rootState.login.activeUser.centerId}/students/${rootState.login.activeUser.userName}/monthly-statistics`,
					{ params: { month } },
				)
				.then((res) => {
					return res.data;
				});
		},
		fetchAverages({ commit, rootState, state }) {
			return window.axios
				.get(
					`center/${rootState.login.activeUser.centerId}/students/${rootState.login.activeUser.userName}/statics-average`,
				)
				.then((res) => {
					commit(types.STATISTICS, res.data);
					return res.data;
				});
		},
		fetchStudentRank({ rootState }) {
			return window.axios
				.get(
					`center/${rootState.login.activeUser.centerId}/students/${rootState.login.activeUser.userName}/student-rank`,
				)
				.then((res) => {
					return res.data;
				});
		},
		clear({ commit }) {
			commit('CLEAR');
		},
	},

	mutations: {
		[types.STATISTICS](state, statistics) {
			state.statistics = statistics;
		},
		CLEAR(state) {
			state.statistics = [];
		},
	},
};
