<template>
	<div
		class="page"
		v-if="studentRank">
		<!-- chart -->
		<v-card
			rounded="lg"
			elevation="3"
			class="mb-2 pt-2 pr-2 mx-auto"
			max-width="700">
			<div
				class="text-center pt-1 d-flex align-center flex-column"
				v-if="
					!staticsAverage.IsNokhba &&
					staticsAverage.FirstSemesterStart &&
					staticsAverage.FirstSemesterEnd &&
					staticsAverage.SecondSemesterStart &&
					staticsAverage.SecondSemesterEnd &&
					new Date() >= new Date(staticsAverage.SecondSemesterStart)
				">
				المعدل التراكمي لكل المواد خلال الفترة السابقة
				<v-radio-group
					dense
					row
					mandatory
					v-model="semesterType">
					<v-radio
						dense
						hide-details
						class="mx-1"
						:value="0"
						:label="'الكل'"></v-radio>
					<v-radio
						dense
						hide-details
						class="mx-1"
						:value="1"
						:label="'فصل اول'"></v-radio>
					<v-radio
						dense
						hide-details
						class="mx-1"
						:value="2"
						:label="'فصل ثاني'"></v-radio>
				</v-radio-group>
			</div>
			<average-chart :data="averages"></average-chart>
		</v-card>

		<!-- average -->
		<v-card
			rounded="lg"
			elevation="3"
			class="px-5 py-2 mb-2 mx-auto"
			max-width="700">
			<v-row no-gutters>
				<!-- get better -->
				<v-col
					cols="7"
					class="text-center">
					<div>
						<span
							v-if="staticsAverage.MonthlyImprove !== '-'"
							class="text-h4 font-weight-bold"
							:class="
								staticsAverage.MonthlyImprove[0] !== '-' ? 'myGreen--text' : 'error--text'
							"
							dir="ltr">
							{{ staticsAverage.MonthlyImprove }}
							<v-icon
								v-if="staticsAverage.MonthlyImprove[0] !== '-'"
								color="myGreen"
								class="mx-n4 text-h4">
								mdi-chevron-double-up
							</v-icon>
							<v-icon
								v-else
								color="error"
								class="mx-n4 text-h4">
								mdi-chevron-double-down
							</v-icon>
						</span>
						<span
							v-else
							class="text-h4 font-weight-bold grey--text">
							-
						</span>
					</div>
					<div>تغير عن الشهر الماضي</div>
				</v-col>

				<v-divider vertical></v-divider>

				<!-- average -->
				<v-col
					cols="5"
					class="text-center">
					<div v-if="staticsAverage.Average.split('/').length > 1">
						<span class="text-h4 font-weight-bold myOrange--text">
							{{ staticsAverage.Average.split('/')[0] }}
						</span>
						/
						{{ staticsAverage.Average.split('/')[1] }}
					</div>
					<div v-else-if="staticsAverage.Average !== 'NaN%'">
						<span class="text-h4 font-weight-bold myOrange--text">
							{{ staticsAverage.Average }}
						</span>
					</div>
					<div
						v-else
						class="text-h4 font-weight-bold grey--text">
						-
					</div>
					<div>معدل تراكمي</div>
				</v-col>
			</v-row>
		</v-card>

		<!-- rank -->
		<v-card
			rounded="lg"
			elevation="3"
			class="pa-4 mb-4 mx-auto"
			max-width="700"
			v-if="studentRank[1].SortStudents || studentRank[0].SortStudents">
			<div class="d-flex justify-space-around align-end">
				<div class="myBlue--text text-h5">ترتيب الطالب</div>
				<div v-if="studentRank[1].SortStudents">
					<span class="myBlue--text text-h4 font-weight-bold">
						{{ studentRank[1].SortStudents?.Rank }}
					</span>
					<span style="width: max-content; display: inline-block"> على الصف </span>
				</div>
				<div v-if="studentRank[0].SortStudents">
					<span class="myBlue--text text-h4 font-weight-bold">
						{{ studentRank[0].SortStudents?.Rank }}
					</span>
					<span style="width: max-content; display: inline-block"> على الشعبة </span>
				</div>
			</div>
		</v-card>

		<!-- month select -->
		<v-select
			v-model="selectedMonth"
			:items="
				staticsAverage.CourseMonths.map((e) => ({
					value: e,
					text: getShortDate(new Date(e)),
				}))
			"
			@change="changeStatics"
			class="mb-2 mx-auto"
			background-color="primary"
			rounded
			solo
			mandatory
			label="اختر شهر"
			hide-details
			style="max-width: 700px"></v-select>

		<v-card
			v-if="monthSelected"
			rounded="lg"
			elevation="3"
			class="pa-4 mx-auto"
			max-width="700">
			<div v-if="loadingStatics">
				<div class="d-flex justify-center">
					<v-progress-circular
						indeterminate
						color="primary"></v-progress-circular>
				</div>
			</div>
			<template v-else-if="monthStatics">
				<div class="d-flex justify-space-around align-end mb-4">
					<div class="text-body-1">المعدل الشهري</div>
					<div v-if="monthStatics.MonthlyAverage.split('/').length > 1">
						<span class="text-h4 font-weight-bold myOrange--text">
							{{ monthStatics.MonthlyAverage.split('/')[0] }}
						</span>
						/
						{{ monthStatics.MonthlyAverage.split('/')[1] }}
					</div>
					<div v-else-if="monthStatics.MonthlyAverage !== 'NaN%'">
						<span class="text-h4 font-weight-bold myOrange--text">
							{{ monthStatics.MonthlyAverage }}
						</span>
					</div>
					<div
						v-else
						class="text-h4 font-weight-bold grey--text">
						-
					</div>
				</div>
				<div class="d-flex justify-space-around align-end">
					<div class="myBlue--text text-h5">ترتيب الطالب</div>
					<div v-if="monthStatics.SpecialtyRank">
						<span class="myBlue--text text-h4 font-weight-bold">
							{{ monthStatics.SpecialtyRank }}
						</span>
						<span style="width: max-content; display: inline-block"> على الصف </span>
					</div>
					<div v-if="monthStatics.Rank">
						<span class="myBlue--text text-h4 font-weight-bold">
							{{ monthStatics.Rank }}
						</span>
						<span style="width: max-content; display: inline-block"> على الشعبة </span>
					</div>
				</div>
			</template>
			<div
				v-else
				class="text-center text-subtitle-1 text--secondary py-1">
				لا توجد بيانات لهذا الشهر
			</div>
		</v-card>
	</div>
</template>

<script>
import AverageChart from '@/components/AverageChart.vue';
import { mapState } from 'vuex';
import { getShortDate } from '@/helpers/helpers';

export default {
	name: 'Statistics',

	components: {
		AverageChart,
	},

	data() {
		return {
			loadingStatics: false,

			studentRank: null,
			getBetter: 0,
			semesterType: 0,

			selectedMonth: null,
			monthStatics: null,
			monthSelected: false,
		};
	},

	computed: {
		averages() {
			switch (this.semesterType) {
			case 1:
				return this.staticsAverage.StaticsAverage.filter(
					(e) =>
						new Date(e.Month) >=
								new Date(
									new Date(this.staticsAverage.FirstSemesterStart).setDate(1),
								).setHours(0, 0, 0, 0) &&
							new Date(e.Month) <=
								new Date(new Date(this.staticsAverage.FirstSemesterEnd).setDate(1)).setHours(
									0,
									0,
									0,
									0,
								),
				);
			case 2:
				return this.staticsAverage.StaticsAverage.filter(
					(e) =>
						new Date(e.Month) >=
								new Date(
									new Date(this.staticsAverage.SecondSemesterStart).setDate(1),
								).setHours(0, 0, 0, 0) &&
							new Date(e.Month) <=
								new Date(new Date(this.staticsAverage.SecondSemesterEnd).setDate(1)).setHours(
									0,
									0,
									0,
									0,
								),
				);
			default:
				return this.staticsAverage.StaticsAverage;
			}
		},

		...mapState({
			staticsAverage: (state) => state.statistics.statistics,
		}),
	},

	methods: {
		getShortDate,
		getGetBetter(val) {
			this.getBetter = val;
		},
		changeStatics() {
			this.monthSelected = true;
			this.loadingStatics = true;
			this.$store
				.dispatch('statistics/fetchStatisticsByMonth', {
					month: this.selectedMonth,
				})
				.then((res) => {
					this.monthStatics = res;
				})
				.finally(() => {
					this.loadingStatics = false;
				});
		},
		fetch() {
			this.$eventBus.$emit('page-loader', true);
			Promise.all([
				this.$store.dispatch('statistics/fetchAverages'),
				this.$store.dispatch('statistics/fetchStudentRank').then((res) => {
					this.studentRank = res;
				}),
			]).finally(() => {
				this.$eventBus.$emit('page-loader', false);
			});
		},
	},

	mounted() {
		this.fetch();
	},
};
</script>
