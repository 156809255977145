import { notificationTypes } from '@/helpers/enums';
import store from '@/store';

export const notificationOptions = {
	icon: '/logo.png',
	dir: 'rtl',
	lang: 'ar-SA',
};

export const notificationPageRedirect = [
	{ type: notificationTypes.message, redirect: '/chat', routeName: 'chat' },
	{ type: notificationTypes.attendance, redirect: '/attendance', routeName: 'attendance' },
	{ type: notificationTypes.newMark, redirect: '/grades', routeName: 'grades' },
	{ type: notificationTypes.newMarks, redirect: '/grades', routeName: 'grades' },
	{ type: notificationTypes.schedule, redirect: '/schedule', routeName: 'schedule' },
	{ type: notificationTypes.newTest, redirect: '/exams', routeName: 'exams' },
	{ type: notificationTypes.testsSchedule, redirect: '/exams', routeName: 'exams' },
	{ type: notificationTypes.transport, redirect: '/', routeName: 'home' },
	{ type: notificationTypes.notes, redirect: '/behavior', routeName: 'behavior' },
];

export const firebase = {
	configs: {
		apiKey: 'AIzaSyCLY5trteKAWE7s2Ze2cej8_am1eUUJ3x0',
		authDomain: 'almashriq-school.firebaseapp.com',
		projectId: 'almashriq-school',
		storageBucket: 'almashriq-school.appspot.com',
		messagingSenderId: '972121759804',
		appId: '1:972121759804:web:6d3d254b0bb76b703a2339',
	},
	publicKey: 'BL5tDeDTJkGWmJxI82hZKUddgb5uc0LMhGGZUVvpe8ccWT6YaL2D9HR6qltWjUJaCkWGXJEEqCPwy3lCc2oAr-0',
};

export const onGetNotification = () => {
	store.dispatch('notification/fetchAll');
};
