<template>
	<div class="page">
		<!-- logo -->
		<v-img
			class="mx-auto mb-7"
			src="../assets/Logo.png"
			height="6rem"
			max-width="6rem"
			contain></v-img>
		<template v-if="about.branches?.length">
			<v-card
				v-for="(branch, i) in about.branches"
				:key="i"
				class="px-1 mb-2 rounded-lg contact-card"
				flat>
				<v-card-title class="text-h6">
					{{ branch.name }}
				</v-card-title>
				<v-card-text>
					<div class="mb-3">
						<v-icon
							color="primary"
							small>
							mdi-map-marker
						</v-icon>
						<a :href="branch.location.url">{{ branch.location.name }}</a>
					</div>
					<div
						class="d-flex mb-2"
						v-for="(number, i) in branch.numbers"
						:key="`phone ${i}`">
						<a
							:href="`tel://${number.phone}`"
							class="black--text">
							<v-icon
								color="primary"
								small>
								mdi-cellphone
							</v-icon>
							{{ number.name }}: {{ number.phone }}
						</a>
					</div>
				</v-card-text>
			</v-card>
		</template>

		<template v-else>
			<!-- mobiles -->
			<v-card
				:href="`tel://${item}`"
				target="_blank"
				v-for="(item, i) in about.mobiles"
				:key="i + ' mobiles'"
				rounded="lg"
				elevation="0"
				class="pa-4 mb-2 contact-card">
				<div class="d-flex justify-space-between">
					<div>
						<v-icon color="primary">mdi-cellphone</v-icon>
					</div>
					<div>{{ item }}</div>
				</div>
			</v-card>

			<!-- phones -->
			<v-card
				:href="`tel://${item}`"
				target="_blank"
				v-for="(item, i) in about.phones"
				:key="i + ' phones'"
				rounded="lg"
				elevation="0"
				class="pa-4 mb-2 contact-card">
				<a class="black--text">
					<div class="d-flex justify-space-between">
						<div>
							<v-icon color="primary">mdi-phone-classic</v-icon>
						</div>
						<div>{{ item }}</div>
					</div>
				</a>
			</v-card>

			<!-- address -->
			<v-card
				v-if="about.address"
				rounded="lg"
				elevation="0"
				class="pa-4 mb-2 contact-card">
				<div class="d-flex justify-space-between">
					<div>
						<v-icon color="primary">mdi-map-marker</v-icon>
						{{ about.address }}
					</div>
				</div>
			</v-card>
		</template>

		<!-- social -->
		<div class="d-flex justify-center mb-1">
			<a
				:href="about.facebook"
				target="_blank">
				<v-icon
					color="primary"
					x-large>
					mdi-facebook
				</v-icon>
			</a>
		</div>
		<div class="d-flex justify-center mb-3">
			<a
				:href="about.privacy"
				target="_blank"
				class="text-center primary--text text-h6">
				سياسة الخصوصية
			</a>
		</div>

		<!-- carousel -->
		<v-carousel
			v-if="images.length"
			:hide-delimiters="true"
			:cycle="true"
			:height="200"
			:interval="3000">
			<v-carousel-item
				v-for="(img, i) in images"
				:key="i">
				<v-img
					:src="img"
					height="100%"
					position="center center"></v-img>
			</v-carousel-item>
		</v-carousel>

		<!-- content -->
		<div
			v-for="(item, i) in about.details"
			:key="i + 'item'">
			<h2 class="primary--text text-h6 mt-5">{{ item.title }}</h2>
			<p
				class="text-justify mb-1"
				v-for="(part, i) in item.content"
				:key="i + 'part'">
				{{ part }}
			</p>
		</div>
	</div>
</template>

<script>
import config from '../helpers/config';

export default {
	name: 'AboutView',

	data() {
		return {
			about: config.about,
			images: [],
		};
	},

	mounted() {
		const image = require.context('../assets/image', true, /[A-Za-z0-9-_,\s]+\.jpg|png$/i);
		image.keys().forEach((e) => {
			this.images.push(image(e));
		});
	},
};
</script>

<style lang="scss" scoped>
.contact-card {
	border: 1px solid #ccc;
}
</style>
