module.exports = {
	colors: {
		main: '#8c0000',
		sec: '#fab735',
		alt: '#8c0000',
	},

	showFunds: false,

	showBuses: false,

	about: {
		mobiles: ['0950555291', '0951229484'],
		phones: [],
		address: 'الشهباء القديمة، خلف مشفى الأندلس ، أمام كافيه ماتريكس',
		data: [0, 0],
		email: null,
		facebook: 'https://www.facebook.com/profile.php?id=100090889058764&mibextid=ZbWKwL',
		privacy: 'https://api.almashriq.edunixsystem.com/privacy-policy/',
		details: [],
	},

	pwa: {
		name: 'مدرسة المشرق الخاصة',
		shortName: 'مدرسة المشرق الخاصة',
		description: 'مدرسة المشرق الخاصة',
	},
};
