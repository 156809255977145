<template>
	<div
		class="page mx-auto"
		style="max-width: 900px">
		<div v-if="marks.length">
			<!-- marks card -->
			<v-card
				v-for="(mark, i) in marks"
				:key="i"
				rounded="lg"
				elevation="3"
				class="overflow-hidden mb-2">
				<div class="d-flex">
					<!-- cart content -->
					<div class="d-flex align-center flex-grow-1 py-3 text-center">
						<!-- grade subject -->
						<div class="name px-2">{{ mark.SubjectName }}</div>

						<v-divider vertical></v-divider>

						<!-- grade info -->
						<v-row
							class="flex-grow-1 px-2"
							align="center">
							<!-- grade -->
							<v-col cols="4">
								<div class="text-caption primary--text">اخر علامة</div>
								<div>{{ mark.Marks[0].Mark }}\{{ mark.Marks[0].TestMaxMark }}</div>
							</v-col>

							<!-- average -->
							<v-col cols="3">
								<div class="text-caption primary--text">معدل</div>
								<div>
									{{
										Number(
											(mark.Marks[0].Mark * 100) / mark.Marks[0].TestMaxMark,
										).toFixed(0)
									}}%
								</div>
							</v-col>

							<!-- status -->
							<v-col cols="5">
								<div class="text-caption primary--text">التقدير</div>
								<div>
									{{ mark.Marks[0].Rate }}
								</div>
							</v-col>
						</v-row>
					</div>

					<!-- button -->
					<router-link
						:to="{
							name: 'grade-view',
							params: { id: mark.SubjectId },
						}"
						class="d-flex">
						<div
							class="btn secondary white--text text-caption d-flex justify-center align-center text-center px-2">
							عرض التفاصيل
						</div>
					</router-link>
				</div>
			</v-card>
		</div>
		<div
			v-else
			class="text-h5 text-center text--secondary py-10">
			لم يتم تسجيل علامات بعد
		</div>
	</div>
</template>

<script>
import { mapState } from 'vuex';

export default {
	name: 'Grades',

	computed: {
		...mapState({
			marks: (state) => state.marks.marks,
		}),
	},

	methods: {
		fetch() {
			this.$eventBus.$emit('page-loader', true);
			this.$store.dispatch('marks/fetchAll').finally(() => {
				this.$eventBus.$emit('page-loader', false);
			});
		},
	},

	mounted() {
		this.fetch();
	},
};
</script>

<style lang="scss" scoped>
.name {
	min-width: 26%;
	max-width: 26%;
}
.btn {
	min-width: 50px;
	max-width: 50px;
}
@media (min-width: 768px) {
	.btn {
		min-width: 150px;
		max-width: 150px;
	}
}
</style>
